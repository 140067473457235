import React from 'react'

const About = () => {
  return (
    <div>About
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
            <div>About</div>
    </div>
  )
}

export default About