import React from 'react';
import './textanimation.css'
import './textanimation.scss'

class HoverableText extends React.Component {
  render() {
    const { text, specialIndexes } = this.props;
    const letters = text.split('').map((char, index) => {
      let className = "hoverable-letter";
      // Apply additional styles based on the specialIndexes prop
      if (char !== ' ') {
        if (specialIndexes && specialIndexes.includes(index)) {
          className += " special-letter";
        }
        return <span key={index} className={className}>{char}</span>;
      } else {
        return <span key={index} >&nbsp;</span>;
      }
    });

    return (
      <h1 className="hoverable-text" >{letters}</h1>
    );
  }
}

export default HoverableText;
