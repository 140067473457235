
import './index.css'
import './index.scss'
import Animatedcursor from '../animatedcursor/Animatedcursor'
import Home from '../Pages/Home/Home'
import Navbar from '../navbar/navbar'
import About from '../Pages/About/About'

const Layout = () => {  
   

    return (
      
        <div>
          <Animatedcursor></Animatedcursor>
         <Navbar></Navbar>
            <section id='home'>
           <Home></Home>
            </section>
            <section id='about'>
              <About></About>
            </section>
            <section id='skills'></section>
            <section id='projects'></section>
            <section id='contact'></section>
            <section id='footer'></section>
            <div id="stars"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
          
        </div>
    )
}

export default Layout