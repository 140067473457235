import React from 'react'

import HoverableText from '../../textanimation/textanimation'
import dp from '../../../assests/dp.png'
import './home.css'
const Home = () => {
   

   
  return (
    <div className='main'>
     
           <div>
            
            <span className='tags tag-main'>&lt;html&gt;</span><br />
            <span className='tags tag-main'>    &lt;body&gt;</span>
              <div className='hometext'>
              <span className='tags'>&lt;h1&gt;</span>
            <HoverableText text="Hi, I'm" specialIndexes={[5]}/>
           <HoverableText text="Himavarshith Reddy" specialIndexes={[]} />   
            <HoverableText text="Full Stack Developer " specialIndexes={[]} />
            <div style={{display:'flex',flexDirection:"row",alignItems:'flex-end'}}>
            <HoverableText text="AI/ML Enthusiast " specialIndexes={[2 ]} />
            <span className='tags'>&lt;/h1&gt;</span>
            </div> 
           
            <p className='homep'>Transforming pixels into possibilities and data into decisions.</p>
            </div>
            
            <span className='tags tag-main'>    &lt;/body&gt;</span><br />
            <span className='tags tag-main'>&lt;/html&gt;</span>
            </div>
            <div className='dpdiv'>
        <img src={dp} className='dp' alt='Himavarshith Reddy'></img>
            </div>
    </div>
  )
}

export default Home