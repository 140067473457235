import React from 'react'
import { useState, useEffect } from 'react';
import './cursor.css'

const Animatedcursor = () => {
    const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });
    const [isVisible, setIsVisible] = useState(true);
    
    useEffect(() => {
        const handleMouseMove = (event) => {
          setPointerPosition({ x: event.clientX, y: event.clientY });
          setIsVisible(true);
        };
    
        const handleMouseLeave = () => {
          setIsVisible(false);
        };
    
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseout', handleMouseLeave);
    
        return () => {
          window.removeEventListener('mousemove', handleMouseMove);
          window.removeEventListener('mouseout', handleMouseLeave);
        };
      }, []);
  return (
    <div
      className={`animated-pointer ${isVisible ? 'visible' : ''}`}
      style={{
        left: `${pointerPosition.x}px`,
        top: `${pointerPosition.y}px`,
        transform: `translate(-50%, -50%)`,
      }}
    />
  )
}

export default Animatedcursor